import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const AccountImage = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& > img': {
    height: '100px',
    borderRadius: '4px',
    maxWidth: '100%',
  },

  '.MuiBox-root': {
    marginTop: '-48px',
    width: '96px',
    height: '96px',
    borderRadius: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    img: {
      width: '84px',
      height: '84px',
      borderRadius: '100%',
    },
  },

  h3: {
    marginTop: '12px',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 500,
  },
}));

export const AccountEnterprise = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& > button': {
    marginTop: '20px',
    borderColor: theme.palette.white[20],
    borderRadius: '8px',
  },
}));

export const AccountEnterpriseInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  margin: '20px 16px 0px 16px',
  paddingBottom: '20px',
  borderBottom: `1px solid ${theme.palette.white[30]}`,

  h4: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 500,
    color: theme.palette.black[20],
  },

  '.MuiBox-root': {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',

    p: {
      fontSize: '14px',
      lineHeight: '16px',
      color: theme.palette.black[10],
    },
  },
}));

export const AccountPersonal = styled(Box)(({ theme }) => ({
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',

  '& > .phone': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '18px',

    button: {
      minWidth: 'unset',
      height: '20px',
      width: '20px',
      padding: '0px',
      fontSize: '16px',
      lineHeight: '19px',
      color: theme.palette.white.main,

      '&.remove': {
        backgroundColor: theme.palette.white[20],

        '&:hover': {
          backgroundColor: theme.palette.error.main,
        },
      },

      '&.add': {
        backgroundColor: theme.palette.success.main,
      },
    },
  },

  '& > button': {
    marginTop: '8px',
    width: '191px',
  },
}));
