import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import React, { useState } from 'react';
import { CallIcon } from '../SvgIcons';
import { useTranslation } from 'react-i18next';

const regex = /((^(\+84|84|0){1})(3|5|7|8|9))+([0-9]{8})$/;

export function AccountInfoBar({ src, text }: { src?: string; text?: string }) {
  return (
    <StyledBox>
      {src && <Image src={src} alt="smt" height={20} width={20} />}
      <Typography component="p">{text}</Typography>
    </StyledBox>
  );
}

export function PhoneEditBar(props: {
  defaultValue: string;
  setOutput: (val: string) => void;
}) {
  const { t } = useTranslation('validate');

  const { defaultValue, setOutput } = props;

  const [value, setValue] = useState<string>(defaultValue);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setOutput(defaultValue);
    if (!e.target.value) {
      setErrorMsg('required');
    } else if (!regex.test(e.target.value)) {
      setErrorMsg('invalidPhone');
    } else {
      setErrorMsg('');
      if (e.target.value !== defaultValue) {
        setOutput(e.target.value);
      }
    }
  };

  return (
    <PhoneEditTextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CallIcon />
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={onChangePhone}
      error={Boolean(errorMsg)}
      helperText={errorMsg && t(`validate:${errorMsg}`)!}
    />
  );
}

const PhoneEditTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  height: 'auto',
  width: '100%',
  borderRadius: '8px',

  // css for TextField
  '.MuiOutlinedInput-root': {
    height: '48px',
    paddingLeft: '12px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    backgroundColor: theme.palette.white[40],

    '.MuiInputAdornment-root': {
      margin: '0px',

      '& svg': {
        height: '20px',
        width: '20px',
      },
    },

    input: {
      height: '100%',
      padding: '0px 12px',

      '&::placeholder': {},
    },

    fieldset: {
      display: 'none',
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  height: '48px',
  width: '100%',
  padding: '0px 12px',
  borderRadius: '8px',
  backgroundColor: theme.palette.white[40],
  display: 'flex',
  alignItems: 'center',
  gap: '12px',

  p: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
  },
}));
