import { Box, Link, Select, styled } from '@mui/material';

export const StyledHeader = styled(Box)(({ theme }) => ({
  height: '72px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledLogo = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  textDecoration: 'none',
  color: theme.palette.black.main,
  cursor: 'pointer',

  img: {
    height: '64px',
    width: '64px',
    borderRadius: '7px',
  },

  div: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 700,

    span: {
      color: theme.palette.primary.main,
    },
  },

  [theme.breakpoints.down('sm')]: {
    img: {
      height: '60px',
      width: '60px',
    },
  },
}));

export const ControlNotLogin = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  width: 'auto',

  '.MuiSelect-select': {
    width: '63px',
    height: '32px',
  },

  '.MuiButton-contained': {
    minWidth: '108px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    textTransform: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    '.MuiButton-contained': {
      minWidth: 'auto',
      width: '80px',
      height: '32px',
      fontSize: '12px',
      lineHeight: '14px',
      padding: '0px',
    },
  },
}));

export const StyledSelectHeader = styled(Select)(({ theme }) => ({
  width: '63px',
  height: '32px',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 400,
  outline: 'none',
  border: `1px solid ${theme.palette.white[10]}`,

  '&:hover': {
    borderColor: theme.palette.primary.main,
  },

  '.MuiSvgIcon-root': {
    right: '3px',
  },

  '.MuiSelect-select': {
    minHeight: 'auto !important',
    textOverflow: 'unset !important',
  },

  fieldset: {
    border: 'none',
  },

  [theme.breakpoints.down('sm')]: {
    width: '58px',
    height: '28px',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
  },
}));

export const ControlLogin = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  width: 'auto',
}));

export const BookingTabs = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  width: 'auto',
}));

export const BookingTabItem = styled(Box)(({ theme }) => ({
  position: 'relative',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '19.5px',
  textDecoration: 'none',
  cursor: 'pointer',
  color: theme.palette.black.main,
  textAlign: 'center',

  '&.active': {
    color: theme.palette.primary.main,
    div: {
      borderBottom: `4px solid ${theme.palette.primary.main}`,
    },
  },

  '&:hover': {
    opacity: 0.8,
    color: theme.palette.primary.main,
    div: {
      borderBottom: `4px solid ${theme.palette.primary.main}`,
    },
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

export const BookingTabItemUnderline = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: -26,
  width: '100%',

  [theme.breakpoints.down('md')]: {
    bottom: -27,
  },

  [theme.breakpoints.down('sm')]: {
    bottom: -27,
  },
}));
