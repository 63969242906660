import { partnerService } from '@/services/partnerService';
import { NotifyStatus } from '@/types/consistency';
import { useMutation, useQuery } from 'react-query';

export default function useNotification() {
  return useMutation(
    async (body: { skip: number; take?: number; where?: any }) => {
      try {
        const { data } = await partnerService.getListNotification(
          body.skip,
          body.take,
          body.where,
        );

        return data;
      } catch (error: any) {
        throw Error(error);
      }
    },
  );
}

export function useUnreadNotification() {
  return useQuery(
    'unread-noti-list',
    async () => {
      try {
        const { data } = await partnerService.getListNotification(1, 999, {
          status: NotifyStatus.NOT_READ,
        });

        return data;
      } catch (error: any) {
        throw Error(error);
      }
    },
    {
      refetchOnWindowFocus: true,
    },
  );
}
