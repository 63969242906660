/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck

import Account from '@/components/Account';
import Notification from '@/components/Notification';
import useStoreApp from '@/store';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { useRouter } from 'next/router';
import { useRouter as useRouterNext13 } from 'next13-progressbar';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginIcon } from '../SvgIcons';
import {
  BookingTabItem,
  BookingTabItemUnderline,
  BookingTabs,
  ControlLogin,
  ControlNotLogin,
  StyledHeader,
  StyledLogo,
  StyledSelectHeader,
} from './style';
const cookie = require('js-cookie');

export enum TabType {
  ETAXI = 'e_taxi',
  TOUR = 'tour',
}

interface Props {
  showTabs?: boolean;
}

function Header({ showTabs }: Props) {
  const { t } = useTranslation(['common']);
  const router = useRouter();
  const routerNext13 = useRouterNext13();
  const { user, languages, setLanguageId } = useStoreApp(
    state => state.globalSlice,
  );
  const [selectedTab, setSelectedTab] = useState<TabType>(TabType.ETAXI);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleLocaleChange = useCallback(
    async (event: any) => {
      const value = event.target.value;
      cookie.set('LOCALE', value);
      const { pathname, query, asPath } = router;
      router.push({ pathname, query }, asPath, {
        locale: value,
      });
    },
    [router],
  );

  useEffect(() => {
    if (typeof router.query.tab === 'string') {
      setSelectedTab(router.query.tab as TabType);
      return;
    }
    setSelectedTab(TabType.ETAXI);
  }, [router.query]);

  useEffect(() => {
    if (languages) {
      const lang = languages.find((e: any) => {
        if (router.locale === 'vi') return e.code.toLowerCase() === 'vn';
        else return e.code.toLowerCase() === 'en';
      });

      if (lang) setLanguageId(lang?.id);
    }
  }, [router.locale, languages]);

  const handleChangeTab = (tab: TabType) => {
    const currentPath = router.pathname;
    const currentQuery = router.query;

    const newQuery = { ...currentQuery, tab: tab };

    const url = {
      pathname: currentPath,
      query: newQuery,
    };

    routerNext13.push(url);
  };

  return (
    <Container maxWidth="xl">
      <StyledHeader>
        <StyledLogo href={user !== null ? `/dashboard` : `/`}>
          <img src="/assets/images/logo.jpg" alt="logo" />
          {/* <Box>
            HOIAN<span> GO</span>
          </Box> */}
        </StyledLogo>

        {showTabs && (
          <BookingTabs>
            <BookingTabItem
              className={selectedTab === TabType.ETAXI ? 'active' : ''}
              onClick={() => handleChangeTab(TabType.ETAXI)}
            >
              {t('eTaxiBooking')}
              <BookingTabItemUnderline />
            </BookingTabItem>
            <BookingTabItem
              className={selectedTab === TabType.TOUR ? 'active' : ''}
              onClick={() => handleChangeTab(TabType.TOUR)}
            >
              {t('tourBooking')}
              <BookingTabItemUnderline />
            </BookingTabItem>
          </BookingTabs>
        )}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <StyledSelectHeader
            onChange={handleLocaleChange}
            value={router.locale}
          >
            <MenuItem value="en">EN</MenuItem>
            <MenuItem value="vi">VN</MenuItem>
          </StyledSelectHeader>
          {user ? (
            <ControlLogin>
              <Notification />
              <Account />
            </ControlLogin>
          ) : (
            <ControlNotLogin>
              <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() =>
                  router.asPath === '/login/'
                    ? router.push(`/`)
                    : router.push(`/login`)
                }
                sx={{
                  paddingX: `${isMobile ? '4' : '12'}px`,
                  minWidth: `${isMobile ? '40' : '64'}px`,
                }}
              >
                <Box display="flex" gap={1} alignItems="center">
                  <LoginIcon />{' '}
                  {!isMobile &&
                    (router.asPath === '/login/' ? t('booking') : t('login'))}
                </Box>
              </Button>
            </ControlNotLogin>
          )}
        </Box>
      </StyledHeader>
    </Container>
  );
}

export default memo(Header);
