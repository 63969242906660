import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import React, { useState } from 'react';
import {
  AccountEnterprise,
  AccountEnterpriseInfo,
  AccountImage,
  AccountPersonal,
} from './style';
import Typography from '@mui/material/Typography';
import { EditIcon, LogoutIcon } from '@/components/SvgIcons';
import Image from 'next/image';
import Button from '@mui/material/Button';
import { AccountInfoBar, PhoneEditBar } from '@/components/AccountInfoBar';
import Backdrop from '@mui/material/Backdrop';
import { handleLocalStorage } from '@/utils/handleLocalStorage';
import account from '../../../public/assets/images/account.png';
import accountBg from '../../../public/assets/images/accountBg.png';
import synodusLogo from '../../../public/assets/images/synodusLogo.png'
import useStoreApp from '@/store';
import { useTranslation } from 'react-i18next';
import { authService } from '@/services/authService';
import { GlobalSlice } from '@/store/global/type';

export default function Account() {
  const { t } = useTranslation('common');
  const { user }: GlobalSlice = useStoreApp(state => state.globalSlice);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const [updateUser, setUpdateUser] = useState<{
    phone1?: string;
    phone2?: string;
    phone3?: string;
  }>({
    phone1: user?.phones[0],
    phone2: user?.phones[1],
    phone3: user?.phones[2],
  });

  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
  const [updateErrMsg, setUpdateErrMsg] = useState<string>('');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsEditing(false);
  };

  const handleLogout = async () => {
    handleLocalStorage.remove('accessToken');
    window.location.href = '/login';
  };

  const handleUpdate = async () => {
    try {
      await authService.updateProfile({
        fullName: user?.fullName,
        address: user?.address,
        ...updateUser,
      });
    } catch (error: any) {
      setUpdateErrMsg(`${t('updateInfo')} ${t('failed')}!`);
    }
  };

  return (
    <>
      <IconButton
        id="account"
        size="small"
        aria-controls={open ? 'account' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          height: '32px',
          width: '32px',
          backgroundImage: `url(${account.src})`,
        }}
      />
      <Backdrop open={open} sx={{ zIndex: 100 }}>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ zIndex: 100 }}
          PaperProps={{
            sx: {
              mt: '20px',
              p: '8px 8px 32px 8px',
              width: '375px',
              borderRadius: '8px',
              background: theme => theme.palette.white.main,
              '& ul': {
                padding: '0',
                '& li:last-child': {
                  marginBottom: '0px',
                },
              },

              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',

              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <AccountImage>
            <Image
              src={accountBg}
              alt="background"
            />
            <Box>
              <Image
                src={synodusLogo}
                alt="logo"
                height={84}
                width={84}
              />
            </Box>
            <Typography component="h3">{user?.fullName}</Typography>

            {!isEditing && (
              <IconButton
                onClick={() => setIsEditing(true)}
                sx={{
                  backgroundColor: theme => theme.palette.other[20],
                  position: 'absolute',
                  top: '114px',
                  right: '8px',
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </AccountImage>

          {isEditing ? (
            <AccountPersonal>
              <AccountInfoBar
                src="/assets/icons/home.svg"
                text={user?.fullName}
              />
              <AccountInfoBar
                src="/assets/icons/location.svg"
                text={user?.address}
              />
              {user?.phones.map((e: string, i: number) => (
                <PhoneEditBar
                  defaultValue={e}
                  setOutput={(val: string) =>
                    setUpdateUser({
                      ...updateUser,
                      [`phone${i + 1}`]: val,
                    })
                  }
                  key={i}
                />
              ))}

              <Button
                variant="contained"
                onClick={handleUpdate}
                disabled={
                  !!user &&
                  JSON.stringify(user.phones) ===
                  JSON.stringify(Object.values(updateUser))
                }
              >
                {t('updateInfo')}
              </Button>

              <Typography
                component="p"
                sx={{
                  mt: '8px',
                  fontSize: '14px',
                  lineHeight: '16px',
                  fontWeight: 400,
                  color: '#EA5455',
                  textAlign: 'center',
                }}
              >
                {updateErrMsg}
              </Typography>
            </AccountPersonal>
          ) : (
            <AccountEnterprise>
              <AccountEnterpriseInfo>
                <Typography component="h4">{t('enterpriseInfo')}</Typography>
                <Box>
                  <Image
                    src="/assets/icons/location.svg"
                    alt="location"
                    height={20}
                    width={20}
                  />
                  <Typography component="p">{user?.address}</Typography>
                </Box>
                <Box>
                  <Image
                    src="/assets/icons/call.svg"
                    alt="call"
                    height={20}
                    width={20}
                  />
                  <Typography component="p">
                    {user?.phones
                      .toString()
                      .replaceAll(',0', ' - 0')
                      .replaceAll(',', '')}
                  </Typography>
                </Box>
              </AccountEnterpriseInfo>

              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<LogoutIcon />}
                onClick={handleLogout}
              >
                {t('logout')}
              </Button>
            </AccountEnterprise>
          )}
        </Menu>
      </Backdrop>
    </>
  );
}
