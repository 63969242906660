import { OrderStatus } from '@/types/consistency';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface IProps {
  wasRead: boolean;
  type: string;
  pickUp?: string;
  dropOff?: string;
  adults?: number;
  // child?: number;
  status?: string;
  date?: string | number;
  handleClick?: () => void;
}

const typeChip = Object.values(OrderStatus);

export default function NotificationItem(props: IProps) {
  const {
    wasRead,
    type,
    pickUp,
    dropOff,
    adults,
    // child,
    status,
    date,
    handleClick,
  } = props;
  const { t } = useTranslation('common');
  const router = useRouter();

  const statusItem = useMemo(
    () => typeChip.find((e: any) => status === e.value),
    [status],
  );

  const isCreated = useMemo(
    () => type === 'Tạo chuyến xe cho Partner thành công',
    [type],
  );

  return (
    <StyledBox
      sx={{
        backgroundColor: !wasRead ? '#F9FBFE' : 'unset',
        borderBottom: '1px solid #DCDCDC',
        minHeight: isCreated ? '97px' : '128px',
      }}
      onClick={handleClick}
    >
      {!isCreated ? (
        <>
          <Typography component="p" className="place">
            <span>{t('pickUp')}: </span>
            <span>{pickUp}</span>
          </Typography>
          <Typography component="p" className="place">
            <span>{t('dropOff')}: </span>
            <span>{dropOff}</span>
          </Typography>
          <Box>
            <Typography component="p">
              <span>{t('numberOfAdults')}: </span>
              <span>{adults}</span>
            </Typography>
            {/* <Typography component="p">
              <span>{t('numberOfChildren')}: </span>
              <span>{child}</span>
            </Typography> */}
          </Box>
          <Box>
            <Typography
              component="p"
              className="status"
              color={`${statusItem?.color}.main`}
            >
              {router.locale === 'vi' ? statusItem?.label! : statusItem?.eLabel}
            </Typography>

            <Typography component="i">
              {moment(date).format('DD/MM/YYYY HH:mm')}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Trans
            i18nKey={`common:notifyCreateForPartner`}
            components={{
              p1: <p style={{ margin: '0px', fontWeight: 500 }} />,
              p2: <p style={{ margin: '0px' }} />,
            }}
          />

          <Typography component="i" style={{ textAlign: 'right' }}>
            {moment(date).format('DD/MM/YYYY HH:mm')}
          </Typography>
        </>
      )}

      {!wasRead && (
        <Box
          sx={{
            position: 'absolute',
            left: '24px',
            top: '50%',
            width: '8px',
            height: '8px',
            borderRadius: '100%',
            backgroundColor: '#0D6EFD',
          }}
        />
      )}
    </StyledBox>
  );
}

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: '20px 18px 20px 44px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '#CEE2F7',
  },

  '& > .MuiBox-root': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '& p': {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,

    'span:nth-of-type(1)': {
      color: theme.palette.black[20],
    },

    '&.place': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '&.status': {
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  },

  '& i': {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    color: theme.palette.black[20],
  },
}));
