import Header from '@/components/Header';
import Toast from '@/components/Toast';
import useNetworkConnection from '@/hooks/useNetworkConnection';
import { SOCKET } from '@/hooks/useWebSocket';
import useSetting from '@/queries/useSetting';
import useStoreApp from '@/store';
import { SOCKET_CONFIG } from '@/types/consistency';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ReactNode, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  hasBackground?: boolean;
  hasFooter?: boolean;
  children?: ReactNode;
  backgroundColor?: string;
  showTabs?: boolean;
  hideHeader?: boolean;
}

function Layout({
  children,
  hasFooter = false,
  hasBackground = false,
  backgroundColor,
  showTabs = false,
  hideHeader = false,
}: IProps) {
  const styleBackground = {
    backgroundImage: {
      xs: 'url("/assets/images/mobileBackground.png")',
      sm: 'url("/assets/images/background.webp")',
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
  };
  const { t } = useTranslation('common');
  const { setSocketBookingMessage } = useStoreApp(state => state.globalSlice);

  useSetting();

  const { isOnline, changed, setChanged } = useNetworkConnection();

  useEffect(() => {
    SOCKET.on(SOCKET_CONFIG.HAS_CHANGE_ORDER_INSIDE, message => {
      setSocketBookingMessage(message);
    });

    return () => {
      SOCKET.off(SOCKET_CONFIG.HAS_CHANGE_ORDER_INSIDE);
    };
  }, []);

  return (
    <>
      {!hideHeader && (
        <Box
          sx={{
            height: '72px',
            boxShadow: hasBackground
              ? 'none'
              : '0px 4px 20px rgba(13, 110, 253, 0.1)',
            borderBottom: hasBackground ? 'none' : '1px solid #F5F6F8',
          }}
        >
          <Header showTabs={showTabs} />
        </Box>
      )}

      <Box
        sx={
          hasBackground
            ? {
              position: 'relative',
              minHeight: `calc(100vh - ${hasFooter ? '72' : '0'}px)`,
              paddingBottom: {
                sm: '120px',
                xs: (hideHeader && !hasFooter) ? "0" : '180px',
              },
              ...styleBackground,
            }
            : {
              position: 'relative',
              minHeight: 'calc(100vh - 72px)',
              backgroundColor: backgroundColor ?? 'unset',
            }
        }
      >
        <Container maxWidth="xl">{children}</Container>

        {hasFooter && (
          <BookingFooter>
            <Typography component="p" textAlign={"center"}>
              Công Ty Cổ Phần Cosevco Hội An  -   Mã số thuế: 0401972080  -   Địa chỉ: Số 29 đường 28/3, Phường Thanh Hà, Thành Phố Hội An, Tỉnh Quảng Nam, Việt Nam.
            </Typography>
            {/*<Typography component="p"> Mã số thuế: 0401972080</Typography>
            <Typography component="p">
              Địa chỉ: Số 29 đường 28/3, Phường Thanh Hà, Thành Phố Hội An, Tỉnh Quảng Nam, Việt Nam.
            </Typography>*/}
          </BookingFooter>
        )}
      </Box>

      <Toast
        open={changed}
        onClose={() => {
          setChanged(false);
        }}
        severity={isOnline ? 'success' : 'error'}
        message={isOnline ? t('networkYES') : t('networkNO')}
      />
    </>
  );
}

export default memo(Layout);

const BookingFooter = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  paddingTop: '15px',
  paddingLeft: '35px',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '60px',
  width: '100%',
  background: 'rgba(39, 32, 13, 0.74)',

  color: theme.palette.primary.main,
  h3: {
    paddingBottom: '10px',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 700,
  },

  p: {
    fontSize: '14px',
    lineHeight: '24px',
  },

  [theme.breakpoints.down('xl')]: {
    padding: '20px',
  },
}));
