import { settingService } from '@/services/settingService';
import useStoreApp from '@/store';
import { IDialCode, ILanguage } from '@/types/interface';
import { handleLocalStorage } from '@/utils/handleLocalStorage';
import moment from 'moment';
import { useQueries } from 'react-query';

export default function useSetting() {
  const {
    setLanguages,
    setMaxChildren,
    setMaxAdults,
    setHotline,
    setDialCodes,
  } = useStoreApp(state => state.globalSlice);

  const settings = handleLocalStorage.get('settings');

  return useQueries([
    {
      queryKey: ['languages'],
      queryFn: async () => {
        try {
          if (
            settings &&
            settings.languages &&
            settings.exp > moment().unix()
          ) {
            return settings.languages;
          }

          const { data } = await settingService.listLanguages();
          return data;
        } catch (error: any) {
          throw Error(error);
        }
      },
      onSuccess(data: ILanguage[]) {
        setLanguages(data);
        handleLocalStorage.set('settings', {
          ...settings,
          languages: data,
          exp: moment().add(1, 'month').unix(),
        });
      },
    },
    {
      queryKey: ['max-adults'],
      queryFn: async () => {
        try {
          if (
            settings &&
            settings.maxAdults &&
            settings.exp > moment().unix()
          ) {
            return { value: settings.maxAdults };
          }

          const { data } = await settingService.getConfig(
            'CONFIG_PASSENGER_NORMAL_MAXIMUM_IN_ORDER',
          );

          return data;
        } catch (error: any) {
          throw Error(error);
        }
      },
      onSuccess(data: { value: number }) {
        setMaxAdults(data.value);
        handleLocalStorage.set('settings', {
          ...settings,
          maxAdults: data.value,
          exp: moment().add(1, 'month').unix(),
        });
      },
    },
    {
      queryKey: ['max-children'],
      queryFn: async () => {
        try {
          if (
            settings &&
            settings.maxChildren &&
            settings.exp > moment().unix()
          ) {
            return { value: settings.maxChildren };
          }

          const { data } = await settingService.getConfig(
            'CONFIG_PASSENGER_HAFT_MAXIMUM_IN_ORDER',
          );

          return data;
        } catch (error: any) {
          throw Error(error);
        }
      },
      onSuccess(data: { value: number }) {
        setMaxChildren(data.value);
        handleLocalStorage.set('settings', {
          ...settings,
          maxChildren: data.value,
          exp: moment().add(1, 'month').unix(),
        });
      },
    },
    {
      queryKey: ['hotline'],
      queryFn: async () => {
        try {
          if (settings && settings.hotline && settings.exp > moment().unix()) {
            return { value: settings.hotline };
          }

          const { data } = await settingService.getConfig('CONFIG_HOTLINE');

          return data;
        } catch (error: any) {
          throw Error(error);
        }
      },
      onSuccess(data: { value: string }) {
        setHotline(data.value);
        handleLocalStorage.set('settings', {
          ...settings,
          hotline: data.value,
          exp: moment().add(1, 'month').unix(),
        });
      },
    },
    {
      queryKey: ['dial-code'],
      queryFn: async () => {
        try {
          if (
            settings &&
            settings.dialCodes &&
            settings.exp > moment().unix()
          ) {
            return settings.dialCodes;
          }

          const { data } = await settingService.getDialCode();

          return data;
        } catch (error: any) {
          throw Error(error);
        }
      },
      onSuccess(data: IDialCode[]) {
        setDialCodes(data);
        handleLocalStorage.set('settings', {
          ...settings,
          dialCodes: data,
          exp: moment().add(1, 'month').unix(),
        });
      },
    },
  ]);
}
