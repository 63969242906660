import Alert from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import React from 'react';

interface IProps {
  open: boolean;
  anchorOrigin?: SnackbarOrigin;
  onClose: () => void;
  message: string;
  severity: 'success' | 'info' | 'warning' | 'error';
}

function SlideLeft(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

export default function Toast(props: IProps) {
  const { open, anchorOrigin, onClose, message, severity } = props;

  return (
    <Snackbar
      anchorOrigin={
        anchorOrigin ?? {
          vertical: 'top',
          horizontal: 'right',
        }
      }
      autoHideDuration={2000}
      TransitionComponent={SlideLeft}
      open={open}
      onClose={onClose}
      sx={{
        top: '80px !important',
        right: '0px !important',

        '.MuiPaper-root': {
          minWidth: '300px',
        },
      }}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
}
