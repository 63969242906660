import React, { useCallback, useEffect, useState } from 'react';
import { ArrowDownIcon, NotificationIcon } from '@/components/SvgIcons';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import NotificationItem from '@/components/NotificationItem';
import useNotification, {
  useUnreadNotification,
} from '@/queries/useNotification';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Badge from '@mui/material/Badge';
import { partnerService } from '@/services/partnerService';
import { SOCKET } from '@/hooks/useWebSocket';
import {
  BookingType,
  NotifyStatus,
  SOCKET_CONFIG,
  SocketType,
  SourceType,
} from '@/types/consistency';
import useStoreApp from '@/store';
import Toast from '../Toast';
import { ITrip } from '@/types/interface';

export default function Notification() {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { user, socketBookingMessage } = useStoreApp(
    state => state.globalSlice,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [list, setList] = useState<any>([]);
  const [skip, setSkip] = useState<number>(1);
  const [badgeContent, setBadgeContent] = useState<number>(0);
  const { mutate } = useNotification();
  const {
    data: listUnread,
    status,
    refetch,
    isRefetching,
  } = useUnreadNotification();

  const handleOpen = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback(
    async (id: string, orderId?: string | null) => {
      try {
        await partnerService.readNotification(id);
        refetch();
      } catch (error) {
        console.log(error);
      } finally {
        if (!!orderId) {
          router.push(`/detail/${orderId}`);
          handleClose();
        }
      }
    },
    [],
  );

  const handleLoadMore = useCallback(() => {
    mutate(
      {
        skip: skip + 1,
      } as any,
      {
        onSuccess: (data: any) => {
          setList([[...list[0], ...data[0]], data[1]] as any);
          setSkip(skip + 1);
        },
      },
    );
  }, [list, skip]);

  useEffect(() => {
    if (!!open) {
      mutate(
        {
          skip: 1,
        } as any,
        {
          onSuccess: (data: any) => {
            setSkip(1);
            setList([...data]);
          },
        },
      );
    }
  }, [open]);

  useEffect(() => {
    if (status === 'success') {
      setBadgeContent(listUnread[1]);
    }
  }, [status, isRefetching]);

  useEffect(() => {
    if (
      !!socketBookingMessage &&
      user?.id &&
      ((socketBookingMessage.data as ITrip)?.collaboratorId === user.id ||
        (socketBookingMessage.data as ITrip[])[0]?.collaboratorId === user.id)
    ) {
      if (open) {
        mutate(
          {
            skip: 1,
            take: skip * 10,
          } as any,
          {
            onSuccess: (data: any) => {
              setList([...data]);
            },
          },
        );
      }

      if (
        socketBookingMessage.type === SocketType.CreateData ||
        socketBookingMessage.type === SocketType.CreateDataQuick ||
        socketBookingMessage.type === SocketType.UpdateStatusComplete ||
        socketBookingMessage.type === SocketType.UpdateStatusCancel
      ) {
        refetch();
      }
    }
  }, [socketBookingMessage]);

  return (
    <>
      <Badge badgeContent={badgeContent} color="primary">
        <IconButton
          id="notification"
          size="small"
          aria-controls={open ? 'notification' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleOpen}
          sx={{
            height: '32px',
            width: '32px',
            border: theme => `1px solid ${theme.palette.white[30]}`,
          }}
        >
          <NotificationIcon />
        </IconButton>
      </Badge>
      <Backdrop open={open} sx={{ zIndex: 100 }}>
        <Popover
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{
            zIndex: 100,
          }}
          PaperProps={{
            sx: {
              mt: '20px',
              p: '0px 0px 8px 0px',
              width: '375px',
              borderRadius: '8px',
              background: theme => theme.palette.white.main,
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',

              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Typography
            component="h3"
            sx={{
              position: 'sticky',
              top: '0',
              pl: '24px',
              pt: '16px',
              pb: '40px',
              borderRadius: '8px 8px 0px 0px',
              fontSize: '18px',
              lineHeight: '21px',
              fontWeight: 500,
              backgroundColor: '#ffffff',
            }}
          >
            {t('notify')}
          </Typography>

          {list.length > 0 && list[0].length > 0 ? (
            <Box
              sx={{
                maxHeight: '572px',
                overflowY: 'auto',
                '::-webkit-scrollbar': {
                  width: '8px',
                },

                '::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 4px grey',
                  borderRadius: '8px',
                },

                '::-webkit-scrollbar-thumb': {
                  background: '#ADCFF2',
                  borderRadius: '10px',
                },

                '::-webkit-scrollbar-thumb:hover': {
                  background: '#5AA0E6',
                },
              }}
            >
              {(list[0] as any).map((e: any, i: number) => (
                <NotificationItem
                  key={i}
                  wasRead={e.status === NotifyStatus.READ}
                  type={e.title}
                  pickUp={e.__order__?.pickUpCity || e.__order__?.pickUpAddress}
                  dropOff={
                    e.__order__?.dropOffCity || e.__order__?.dropOffAddress
                  }
                  adults={e.__order__?.passengerNormal ?? 0}
                  // child={e.__order__?.passengerHalf ?? 0}
                  status={e.__order__?.status}
                  date={e?.createdAt}
                  handleClick={() => handleClick(e?.id, e?.orderId)}
                />
              ))}

              {list.length > 0 && list[0].length < list[1] && (
                <Typography
                  sx={{
                    m: '10px 0px',
                    cursor: 'pointer',
                    textAlign: 'center',

                    '&:hover': {
                      color: 'blue',
                    },
                  }}
                  component="p"
                  onClick={handleLoadMore}
                >
                  {t('loadMore')}
                </Typography>
              )}
            </Box>
          ) : (
            <Typography
              sx={{
                mb: '10px',
                textAlign: 'center',
              }}
              component="p"
            >
              {t('noNotify')}
            </Typography>
          )}
        </Popover>
      </Backdrop>
    </>
  );
}
