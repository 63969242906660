import { apiClient } from './apiService';

export const settingService = {
  listLanguages() {
    return apiClient.request({
      method: 'POST',
      url: '/mobileCustomer/find_language',
      data: { where: { isDeleted: false } },
    });
  },
  getConfig(code: string) {
    return apiClient.request({
      method: 'POST',
      url: '/mobileCustomer/find_setting_string',
      data: { where: { isDeleted: false, code } },
    });
  },
  getDialCode() {
    return apiClient.request({
      method: 'POST',
      url: '/passengers/get_dial_code',
    });
  },
};
