import React, { useEffect, useState } from 'react';

function useNetworkConnection() {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  const [changed, setChanged] = useState<boolean>(false);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      setChanged(true);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  return { isOnline, setIsOnline, changed, setChanged };
}

export default useNetworkConnection;
